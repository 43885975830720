import React, { useEffect, useState } from "react";
// import style css
import "./Results.scss";
// import image
import DownloadSvg from "../../assets/images/allSvgUsedOnSite/ResultsSvgDownload.svg";

// import ScrollReveal
import ScrollReveal from "scrollreveal";
import { useContext } from "react";
import { TrContext } from "../../TrUseContext/TrUseContext";
import looptech from "../../Helpers/looptech";
import urls from "../../ApiValues/urls";
import LoadingComponent from "../../Components/LoadingComp/LoadingComponent";

function Results() {
	const { lang } = useContext(TrContext);
	const [ResultsDatas, setResultsDatas] = useState([]);
	const [loading, setLoading] = useState(true);
	const getResultsPageDatas = async () => {
		try {
			const ResultsDataRes = await looptech.api().get(urls.results(lang));
			setResultsDatas(ResultsDataRes.data.data);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		getResultsPageDatas();
	}, [lang]);

	useEffect(() => {
		if (ResultsDatas.length > 0)
			ScrollReveal().reveal(".table-mobile .table", {
				interval: 500,
				reset: true,
			});
	}, [ResultsDatas]);

	//  console.log("resultus page datas", ResultsDatas);
	return (
		<>
			{loading ? (
				<LoadingComponent />
			) : (
				<section id="results">
					<div className="container">
						<h2>{looptech.translate("results").toUpperCase()}</h2>

						<div className="table-web">
							<ul>
								<li className="event">Event</li>
								<li className="date">Date</li>
								<li className="individual">Individual final list</li>
								<li className="team">Team final list</li>
							</ul>
							<hr />
							{ResultsDatas.map((item) => (
								<div key={item.id} className="table-date">
									<h5 className="event" title={item.title}>
										{item.title}
									</h5>
									<p className="date">{item.date}</p>
									<a className="download individual" href={item.individual_list ? `${looptech.baseUrlImage}${item.individual_list?.download_link}` : "#"} target="_blank" rel="noreferrer" download={item.individual_list?.original_name}>
										<img src={DownloadSvg} alt="burada download svg var" />
									</a>
									<a className="download team" href={item.team_list ? `${looptech.baseUrlImage}${item.team_list?.download_link}` : "#"} target="_blank" rel="noreferrer" download={item.team_list?.original_name}>
										<img src={DownloadSvg} alt="burada download svg var" />
									</a>
								</div>
							))}
							<hr />
						</div>

						<div className="table-mobile">
							{ResultsDatas.map((item) => (
								<div key={item.id} className="table">
									<ul>
										<li>Event</li>
										<li>Date</li>
										<li>Individual final list</li>
										<li>Team final list</li>
									</ul>
									<div className="table-date">
										<h5 title={item.title}>{item.title}</h5>
										<p>{item.date}</p>
										<a href={item.individual_list ? `${looptech.baseUrlImage}${item.individual_list?.download_link}` : "#"} target="_blank" rel="noreferrer" download={item.individual_list?.original_name}>
											<img src={DownloadSvg} alt="burada download svg var" />
										</a>
										<a href={item.team_list ? `${looptech.baseUrlImage}${item.team_list?.download_link}` : "#"} target="_blank" rel="noreferrer" download={item.team_list?.original_name}>
											<img src={DownloadSvg} alt="burada download svg var" />
										</a>
									</div>
								</div>
							))}
						</div>
					</div>
				</section>
			)}
		</>
	);
}

export default Results;
